<template>
  <section class="reviews-block">
    <div class="container">
      <div class="reviews-block__head">
        <h2>{{ values.heading }}</h2>
        <nuxt-link
          v-if="values.link"
          :to="values.link"
          class="--link"
        >
          {{ values.link_title }}
          <img
            src="~/assets/img/svg/common/arrow.svg"
            alt="стрелка"
          >
        </nuxt-link>
      </div>

      <div
        v-if="descriptionFromValues"
        v-html="descriptionFromValues"
        class="reviews-block__description"
      />

      <template v-if="$viewport.isGreaterOrEquals('lg')">
        <div class="reviews-block__items">
          <template
            v-for="(_item) in reviews"
            :key="`reviews-${_item.id}`"
          >
            <ReviewCard
              :is-manager="isManager"
              :item="_item"
            />
          </template>
        </div>
      </template>
      <template v-if="!$viewport.isGreaterOrEquals('lg')">
        <MobileSwiper
          :is-manager="isManager"
          :reviews="reviews"
        />
        <nuxt-link
          v-if="values.link"
          :to="values.link"
          class="btn btn-outline reviews-block__button-mobile"
        >
          {{ values.link_title }}
        </nuxt-link>
      </template>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { useNuxtApp } from 'nuxt/app';
import type { IBlockDefaultProps } from '#sitis/internal/models/common';
import useSitisUserManager from '~/composables/useSitisUserManager';
import {computed} from "#imports";

const ReviewCard = defineAsyncComponent(() => import('~/components/reviews/ReviewCard.vue'));
const MobileSwiper = defineAsyncComponent(() => import('~/components/reviews/MobileSwiper.vue'));

const props = defineProps<IBlockDefaultProps>();
const { $api, $viewport } = useNuxtApp();

const { userManagerInfo } = useSitisUserManager();

const reviews = ref([]);
const getReviews = async () => {
  reviews.value = await $api.agent.get('/reviews?per-page=3&expand=files&sort=rnd').then((res) => {
    return res._data;
  }).catch(() => {
    return [];
  });
};
const isManager = computed(() => {
  return Boolean(userManagerInfo.value);
});

const descriptionFromValues = computed(() => {
  if ($viewport.isLessThan('lg')) {
    return props.values.description_mobile;
  }
  return props.values.description_desktop;
})

onMounted(async () => {
  await getReviews();
});

</script>

<style lang="scss" scoped>
.reviews-block {
	padding: 40px 0;
  margin-top: 14px;
	box-sizing: border-box;
	background-color: white;
	border-radius: 30px 30px 0 0;
	position: relative;
	z-index: 1;
}

.reviews-block__head {
	display: flex;
	align-items: center;
	margin-bottom: 32px;

	.--link {
		display: flex;
		align-items: center;
		margin-left: auto;
		color: #000;
		font-variant-numeric: lining-nums proportional-nums;
		font-size: 16px;
		font-weight: 500;
		line-height: 24px;

		img {
			transform: rotate(180deg);
			width: 20px;
			height: 20px;
			margin-left: 6px;
		}

		&:hover {
			text-decoration: underline;
		}
	}
}

.reviews-block__description {
  font-weight: 500;
  line-height: 130%;
  padding-bottom: 32px;
}

.reviews-block__items {
	display: flex;
	flex-wrap: wrap;
	margin-top: -32px;
	margin-left: -32px;

	& > * {
		width: calc(100% / 3 - 32px);
		margin-top: 32px;
		margin-left: 32px;
	}
}

.reviews-block__button-mobile {
	border-color: #404040;
	margin-top: 18px;
	height: 48px;
}

@media (max-width: 1359px) {
  .reviews-block__items {
    margin-top: -20px;
    margin-left: -20px;
    & > * {
      width: calc(100% / 3 - 20px);
      margin-top: 20px;
      margin-left: 20px;
    }
  }
}
@media (max-width: 1023px) {
	.reviews-block {
		padding: 40px 0;
	}
	.reviews-block__head {
		.--link {
			display: none;
		}
	}
}
</style>
